import * as types from "../../../constants";

const initialState = {
  loading: false,
  generateReportLoading: false,
  measurementInfoLoading: false,
  generatedReportData: {},
  testList: [],
  currentTest: {},
  filteredTestList: [],
  filterSettings: {
    duplicate: 0,
    anomaly: 0,
    all: 1,
  },
  filterEnabled: false,
  sort: { order: "desc", orderBy: "measurementStartedLocal" },
};

const reducer = (state = initialState, action) => {
  let updatedTest = {};
  switch (action.type) {
    case types.SU_TEST_SORT:
      return {
        ...state,
        sort: { order: action.payload.order, orderBy: action.payload.orderBy },
      };
    case types.SU_TEST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.SU_GENERATE_REPORT_LOADING:
      return {
        ...state,
        generateReportLoading: true,
        loading: true,
      };
    case types.SU_GENERATE_REPORT_LOADING_DISABLE:
      return {
        ...state,
        generateReportLoading: false,
        loading: false,
      };
    case types.SU_GENERATE_REPORT:
      return {
        ...state,
        generatedReportData: { ...action.payload },
        generateReportLoading: false,
        loading: false,
      };
    case types.SU_TEST_LOADING_DISABLE:
      return {
        ...state,
        loading: false,
      };
    case types.SU_RETRIEVE_TEST_LIST:
      return {
        ...state,
        testList: [...action.payload],
        loading: false,
      };
    case types.SU_RETRIEVE_TEST_INFO:
      return {
        ...state,
        currentTest: { ...action.payload },
        loading: false,
      };
    case types.SU_UPDATE_TEST_INFO:
      updatedTest = Object.assign(state.currentTest, action.payload);

      return {
        ...state,
        currentTest: updatedTest,
        loading: false,
      };
    case types.SU_UPDATE_MEASUREMENT_INFO:
      updatedTest = Object.assign(state.currentTest, action.payload);

      return {
        ...state,
        currentTest: updatedTest,
        measurementInfoLoading: false,
      };
    case types.SU_CURRENT_TEST:
      return {
        ...state,
        currentTest: action.payload,
      };
    case types.SU_DELETE_TEST:
      let newTestList = [];

      newTestList = state.testList.filter(
        (test) => test.testId !== action.payload
      );

      return {
        ...state,
        testList: [...newTestList],
        loading: false,
      };
    case types.SU_TEST_FILTER:
      let duplicatesList = [];
      let anomaliesList = [];

      if (action.payload.duplicate) {
        duplicatesList = state.testList.filter((test) => test.duplicateTest);
      }

      if (action.payload.anomaly) {
        anomaliesList = state.testList.filter((test) =>
          test.anomalies.includes(1)
        );
      }

      if (action.payload.all) {
        return {
          ...state,
          filteredTestList: [],
          filterEnabled: false,
          filterSettings: {
            duplicate: 0,
            anomaly: 0,
            all: 1,
          },
        };
      }

      const filteredTestList = duplicatesList.concat(anomaliesList);
      const uniqueList = Array.from(new Set(filteredTestList));

      return {
        ...state,
        filteredTestList: uniqueList,
        filterEnabled: true,
        filterSettings: { ...action.payload },
      };
    case types.SU_RESET_TEST_LIST:
      return {
        ...state,
        currentTest: {},
        testList: [],
      };
    default:
      return state;
  }
};

export default reducer;
