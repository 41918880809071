export const apiEndPoint = "https://pro-dev.ecosense.io/api/v1";
// export const apiEndPoint = "https://dev-api.cloud.ecosense.io/api/v1";
export const accessUrl = "https://dashboard.pro-dev.ecosense.io";
export const awsXApiKey = "WdsIuJkuEF7B75Xdc0vo56pUI63rCPbm7fzI7iPF";
export const showSingleMenu = true;

export const awsAmplifyAuth = {
  region: "us-east-1",
  userPoolId: "us-east-1_cysufm7cy",
  identityPoolId: "us-east-1:245c8e9f-637c-4399-ae64-285df3a9e8e4",
  userPoolWebClientId: "38tbptbgmkoqsb6chbq0qduthb",
};
