/* eslint-disable import/first */
import { showSingleMenu } from "../env";
import {
  projectRoutes,
  projectRoutesForSideBar,
  singleTestRoutes,
  singleTestRoutesForSideBar,
  authRoutes,
  settingsRoutes,
  settingsForSideBar,
  supportPageRoutes,
  supportPageRoutesForSideBar,
} from "./RouteComponents";
// import { landingRoutes } from "./PreliminaryRouteComponents";

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  projectRoutes,
  showSingleMenu ? singleTestRoutes : "",
  settingsRoutes,
  // supportPageRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes using the Presentation layout
// export const presentationLayoutRoutes = [landingRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  projectRoutesForSideBar,
  showSingleMenu ? singleTestRoutesForSideBar : "",
  settingsForSideBar,
  // supportPageRoutesForSideBar,
];
